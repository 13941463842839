//plugins
import axios from 'axios'

//helpers
import { prod } from './mode'

const { REACT_APP_URL_DEV, REACT_APP_URL_PROD } = process.env
const api = axios.create({
    baseURL: `${prod ? REACT_APP_URL_PROD : REACT_APP_URL_DEV}/api/`
})
//
api.interceptors.request.use(async config => {
    config.params = config.params || {}

    try {
        let auth = localStorage.getItem('auth')

        if (auth) {
            auth = JSON.parse(auth)
            const token = auth?.data?.access_token || null

            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`
            }
        }
    } catch (e) {
        console.error(e)
    }

    return config
})

export default api